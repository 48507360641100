import styled from "styled-components";

export const ContainerLinkTree = styled.div`

     display:flex;
     flex-direction: column;
     padding-top: 10px;
     padding-right: 300px;
     padding-left: 300px;

     @media (max-width: 767px){
          padding: 13px !important;
          padding-top: 20px !important;
          padding-bottom: 20px !important;
     }
`;

export const LinkElement = styled.div`

     margin-bottom: 16px;
     transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
     border: 2px solid rgb(61, 59, 60);
     background-color: rgb(61, 59, 60);
     color: rgb(255, 255, 255);
     border-radius: 0px;
     text-align: center;
     text-decoration: none;
     

     @media (max-width: 767px ){
          border-radius: 0px;
     }
`;

export const Button = styled.a`

     border: none;
     display: inline-flex;
     -webkit-box-align: center;
     align-items: center;
     -webkit-box-pack: center;
     justify-content: center;
     height: auto;
     position: relative;
     padding: 16px 20px;
     margin: 0px;
     font-family: inherit;
     font-weight: inherit;
     font-size: inherit;
     width: 100%;13px
     cursor: pointer;
     appearance: none;
     text-align: center;
     box-sizing: border-box;
     text-decoration: none;
     color: #fff;

     @media (max-width: 767px ){
          
          padding: 8px 2px !important;
          font-size: 14px;
          font-weight: 700;
          margin-top: 5px;
          margin-bottom: 5px;
     }

     ${LinkElement}:hover & {
          background-color: #fff;
          color: rgb(61, 59, 60);
       }
`;

export const HeaderContainer = styled.div`
     margin-bottom: 16px;
`;

export const Header = styled.div`
     display: flex;
     -webkit-box-align: center;
     align-items: center;
     flex-direction: column;
     margin-top: 0px;
     margin-bottom: 32px;
     width: 100%;
     height: 100%;
`;

export const ImageHeader = styled.div`
     margin-top: 25px;
`;

export const Image = styled.img`
     border-radius: 50%;
     width: 105px;
     height: 105px;
     display: block;
     object-fit: contain;
     object-position: initial;
     filter: none;
`;

export const TextHeader = styled.div`
     margin-left: 12px;
     margin-right: 12px;
     max-width: 100%;
`;

export const Title = styled.h1`
     margin: 0px;
     font-weight: 300;
     font-size: 30px;
     line-height: 1.5;
     text-overflow: ellipsis;
     max-width: 100%;
     white-space: nowrap;
     overflow: hidden;
     color: rgb(153, 153, 153);
     margin-top: -30px;
`;

export const LogoContainer = styled.div`
     display: flex;
     flex-direction: row;
     justify-content: center;
`;

export const LogoImage = styled.img`
     width: 35px;
     margin-left: 10px;
     margin-right: 10px;
     margin-top: 20px;
`;