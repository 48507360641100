import * as React from "react"
import { Link } from "gatsby"
import {
     HeaderContainer,
     Header,
     ImageHeader,
     TextHeader,
     Title,
     ContainerLinkTree,
     LinkElement,
     Button,
     Image,
     LogoContainer,
     LogoImage
} from "../components/linkTreeComponents";

const Year = new Date().getFullYear();

const Linktree = () => (
       <>
       <HeaderContainer>
          <Header>

               <ImageHeader>
                    <Image src="/img/VIP-40.svg"></Image>
               </ImageHeader>
               
               <TextHeader>
                    <Title>Plan Ortodoncia {Year}</Title>
               </TextHeader>
          </Header>
       </HeaderContainer>

        <ContainerLinkTree>
             <LinkElement>
               <Button href="https://dentalvipcaracas.com/especialidades/ortodoncia">VISITAR PÁGINA WEB</Button>
             </LinkElement>

             <LinkElement>
               <Button href="https://t.me/dentalvipcaracas">CONSULTA VÍA TELEGRAM</Button>
             </LinkElement>

             <LinkElement>
               <Button href="https://api.whatsapp.com/send?phone=+584242414841">CONSULTA VÍA WHATSAPP</Button>
             </LinkElement>

             <LinkElement>
               <Button href="https://google.co.ve">CITA ONLINE</Button>
             </LinkElement>
        </ContainerLinkTree>

        <LogoContainer>
             <div>
               <a href="https://m.me/dentalvipcaracas"><LogoImage src="/img/VIP-36.svg"></LogoImage></a>
             </div>
            
             <div>
               <a href="https://instagram.com/dental_vip"><LogoImage src="/img/VIP-37.svg"></LogoImage></a>
             </div>

             <div>
               <a href="mailto:info@dentalvipcaracas.com"><LogoImage src="/img/VIP-38.svg"></LogoImage></a>
             </div>

             <div>
               <a href="tel:+582122613732"><LogoImage src="/img/VIP-39.svg"></LogoImage></a>
             </div>  
          
        </LogoContainer>
       </>
  )
  
  export default Linktree